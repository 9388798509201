<template>
  <div>
    <!-- tabs -->
    <v-tabs v-model="tab">
      <v-tab>Timeline</v-tab>
      <v-tab>Status</v-tab>
      <v-tab>Calendar</v-tab>
    </v-tabs>

    <!-- tabs' items -->
    <v-tabs-items v-model="tab">
      <!-- project timeline -->
      <v-tab-item>
        <project-tasks-timeline
          :record="record"
          :project="project"
        ></project-tasks-timeline>
      </v-tab-item>

      <!-- project status -->
      <v-tab-item>
        <project-tasks-status-pie-chart
          :project="project"
        ></project-tasks-status-pie-chart>
      </v-tab-item>

      <!-- project calendar-->
      <v-tab-item>
        <project-task-calendar
          :record="record"
          :project="project"
        ></project-task-calendar>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "ProjectChart",
  components: {
    ProjectTasksStatusPieChart: () =>
      import("@/components/project/ProjectTasksStatusPieChart.vue"),
    ProjectTasksTimeline: () =>
      import("@/components/project/ProjectTasksTimeline.vue"),
    ProjectTaskCalendar: () =>
      import("@/components/project/ProjectTaskCalendar.vue")
  },
  data() {
    return {
      tab: 0
    };
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: number, definitionId: number, workflowInstanceId: number, workflowTypeId: number, projectName: String, recordId: number, subject: String, owner: String, projectManagerId: number, templateId: number, templateName: String, workflowInstanceId: number, status: number, statusInfo: String, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}}
     */
    project: undefined,

    /**
     * project's record
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[]}}
     */
    record: undefined
  }
};
</script>
